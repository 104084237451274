import {
  EventDataResponse,
  TCreateAppointmentResponse,
} from '@/lib/react-query/Queries/gCalendar/types';
import { useEffect, useState } from 'react';
import {
  useGetEnabledMonthDays,
  useGetNextMonthsEnabledDays,
} from '@/lib/react-query/Queries/gCalendar/useGetEnabledMonthDates';

import { Button } from '@/components/Button/Button';
import { ClockIcon } from '@mui/x-date-pickers';
import GwatCalendarPicker from './GwatCalendarPicker';
import GwatCalendarTimezonePicker from './GwatCalendarTimezonePicker';
import TimeSlotsPicker from './TimeSlotsPicker';
import dayjs from 'dayjs';
import { setLastEnabledDate } from '@/state/slices/gcalendar';
import { store } from '@/state/store';
import timezones from 'timezones-list';
import { useAppSelector } from '@/state/hooks';
import { useCreateAppointment } from '@/lib/react-query/Queries/gCalendar/useCreateAppointment';
import { useGetMonthTimeSlots } from '@/lib/react-query/Queries/gCalendar/useGetMonthTimeSlots';
import { useQueryClient } from '@tanstack/react-query';

interface GwatCalendarProps {
  onAppointmentCreated: (result: TCreateAppointmentResponse) => void;
  onCreateAppointmentError?: (err: any) => void;
  eventData: EventDataResponse | undefined;
}

function GwatCalendar({
  onAppointmentCreated,
  onCreateAppointmentError,
  eventData,
}: GwatCalendarProps): JSX.Element {
  const queryClient = useQueryClient();

  const { tid } = useAppSelector((state) => state.user);

  const [tz, setTz] = useState<string>(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ); // Set default to user's timezone

  const tzCodes = timezones.map((tz) => tz.tzCode);

  const [selectedSlot, setSelectedSlot] = useState<string | null>(null);

  const currentDate = dayjs();
  const currentMonth = currentDate.month();
  const currentYear = currentDate.year();

  const { mutation, isLoading: isCreateAppointmentLoading } =
    useCreateAppointment();

  const { data: enabledMonthDays, isLoading: enabledMonthDaysIsLoading } =
    useGetEnabledMonthDays(currentYear, currentMonth || 1, tz);

  const {
    data: nextMonthsEnabledDays,
    isLoading: isNextMonthsEnabledDaysLoading,
  } = useGetNextMonthsEnabledDays({
    year: currentYear,
    month: currentMonth || 1,
    monthsCount: 12,
    tz,
  });

  const { data: monthTimeSlots, isLoading: isMonthTimeSlotsLoading } =
    useGetMonthTimeSlots(tz, enabledMonthDays);

  const [date, setDate] = useState<dayjs.Dayjs>();

  // Set date
  useEffect(() => {
    if (enabledMonthDays && enabledMonthDays.length) {
      setDate(dayjs(enabledMonthDays[0]));
    }
  }, [enabledMonthDays, enabledMonthDaysIsLoading]);

  // Set selected slot
  useEffect(() => {
    if (monthTimeSlots && date) {
      const dateSlots = monthTimeSlots[date.format('YYYY-MM-DD')];
      if (dateSlots && dateSlots.length) {
        const isSelectedDateAvailable =
          monthTimeSlots &&
          selectedSlot &&
          dateSlots.find((date) => date.displayTime === selectedSlot);

        if (!isSelectedDateAvailable) setSelectedSlot(dateSlots[0].displayTime);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthTimeSlots, date]);

  // Set last enabled date in the store
  useEffect(() => {
    if (nextMonthsEnabledDays && nextMonthsEnabledDays.results.length) {
      const lastEnabledDate =
        nextMonthsEnabledDays?.results[
          nextMonthsEnabledDays.results.length - 1
        ];

      store.dispatch(setLastEnabledDate(lastEnabledDate));
    }
  }, [nextMonthsEnabledDays, isNextMonthsEnabledDaysLoading]);

  const createAppointmentHandler = async () => {
    if (isCreateAppointmentLoading) {
      return;
    }

    const appointmentDate = date?.format('YYYY-MM-DD');

    if (monthTimeSlots && appointmentDate) {
      const start = monthTimeSlots[appointmentDate]?.find(
        (s) => s.displayTime === selectedSlot
      )?.from;

      if (tz && appointmentDate && start && tid) {
        try {
          const res = await mutation.mutateAsync({
            timezone: tz,
            date: appointmentDate,
            start,
            tid,
          });

          if (res.id) {
            onAppointmentCreated(res);
          }
        } catch (error) {
          if (onCreateAppointmentError) {
            onCreateAppointmentError(error);
          } else {
            console.error(error);
            // TODO: handle Error + Sentry Report
          }
        }
      }
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center w-full">
        <div className="flex-1">
          <GwatCalendarPicker
            date={date || null}
            monthTimeSlots={monthTimeSlots || null}
            enabledMonthDays={enabledMonthDays || null}
            onChange={setDate}
            disabled={isCreateAppointmentLoading || !enabledMonthDays}
          >
            <GwatCalendarTimezonePicker
              options={tzCodes.map((tz) => ({ value: tz, label: tz }))}
              currentTz={tz}
              onChange={(tz: string) => {
                setTz(tz);

                queryClient.invalidateQueries({
                  queryKey: ['get-month-time-slots'],
                });

                queryClient.invalidateQueries({
                  queryKey: ['get-enabled-month-days'],
                });
              }}
              disabled={isCreateAppointmentLoading}
            />
          </GwatCalendarPicker>
        </div>
        <div
          id="gwat-calendar-content"
          className="flex flex-1 flex-col h-full items-center justify-center md:border-l border-light-900 md:pl-4 md:py-4 mt-8 md:mt-0"
        >
          {!date && (
            <>
              <div className="bg-light-800 animate-pulse min-h-[30px] rounded-md w-full"></div>
              <div className="flex items-center justify-center h-[40px]">
                <div className="flex h-4 min-h-4 min-w-[120px] bg-light-800 animate-pulse rounded-md"></div>
              </div>
            </>
          )}
          {date && (
            <>
              <div
                className="text-teal-800 font-medium"
                style={{ fontSize: '20px' }}
              >
                {date && date.format('dddd, MMMM D, YYYY')}
              </div>
              <div className="flex items-center justify-center pt-2 pb-4">
                <ClockIcon />
                <span className="text-[14px] leading-[12px] relative top-[0.5px] pl-1">
                  Duration: {eventData?.duration} min
                </span>
              </div>
            </>
          )}
          <div className="md:max-h-[208px] md:min-h-[208px] overflow-auto w-full">
            <TimeSlotsPicker
              monthTimeSlots={monthTimeSlots || null}
              date={date || null}
              selectedSlot={selectedSlot}
              onChange={setSelectedSlot}
              loading={isMonthTimeSlotsLoading}
              disabled={isCreateAppointmentLoading}
            />
          </div>

          <Button
            text="Confirm time & schedule call"
            size="medium"
            className={`mt-4 w-full ${
              !isMonthTimeSlotsLoading && !isCreateAppointmentLoading
                ? '!bg-[#00C3EE]'
                : ''
            }`}
            clickHandler={createAppointmentHandler}
            isLoading={isCreateAppointmentLoading}
            isDisabled={isMonthTimeSlotsLoading}
          />
        </div>
      </div>
    </div>
  );
}

export { GwatCalendar };
